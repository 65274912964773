var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: {
                placeholder: _vm.$t("statManager.pointContent"),
                clearable: ""
              },
              on: { change: _vm.handleCommand },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.types, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      [1, 7, 21, 26].indexOf(_vm.listQuery.type) >= 0
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.packType"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.packType))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2959015719
                )
              }),
              [1].indexOf(_vm.listQuery.type) >= 0
                ? _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.peopleNumber"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.queryResult))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1824536005
                    )
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.peopleCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.distinctQueryResult))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  982632863
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.succActivityNum"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.succQueryResult))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3489077091
                )
              }),
              [7, 21, 26].indexOf(_vm.listQuery.type) >= 0
                ? _c(
                    "div",
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t("statManager.count"),
                          width: "120px",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.queryResult))
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1824536005
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 9
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.peopleClientNumber"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.queryResult))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1824536005
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.peopleBuyNumber"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.distinctQueryResult))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  982632863
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.buyPruductNumber"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.succQueryResult))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3489077091
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 10
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("奖励一完成次数"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row[0]))])]
                      }
                    }
                  ],
                  null,
                  false,
                  3296229822
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("奖励二完成次数"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row[1]))])]
                      }
                    }
                  ],
                  null,
                  false,
                  2991457375
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("奖励三完成次数"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row[2]))])]
                      }
                    }
                  ],
                  null,
                  false,
                  120760188
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("通过邮件完成次数"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row[3]))])]
                      }
                    }
                  ],
                  null,
                  false,
                  4011980829
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 12
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.peopleBuyNumber"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.succQueryResult))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3489077091
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.buyPruductNumber"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.distinctQueryResult))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  982632863
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.systemBuyCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.queryResult))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1824536005
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 13
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.date"),
                  width: "240px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1531225394
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("agents.playerId"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.uid))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1905034046
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.failReason"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.reason))])]
                      }
                    }
                  ],
                  null,
                  false,
                  1737496290
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.isSystem"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.isSystem))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1291723481
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 14
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.allBuyCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.allBuyCount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  269098058
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.newBuyUser"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.newBuyUser))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4183604197
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 15
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.packType"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.packType))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2959015719
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.allBuyCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.allBuyCount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  269098058
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.newBuyUser"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.newBuyUser))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4183604197
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.allBuyUser"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.allBuyUser))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  805129080
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.refreshTime"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.refreshTime))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  897025742
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 17
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.packType"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.packType))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2959015719
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.allBuyCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.allBuyCount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  269098058
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.newBuyUser"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.newBuyUser))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4183604197
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.allBuyUser"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.allBuyUser))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  805129080
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 31
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.diamondConsume"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.diamondConsume))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2943041838
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.drawLotteryCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.drawLotteryCount))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1087066344
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.shareCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.shareCount))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4069539016
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.listQuery.type === 32
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: _vm.tableKey,
              attrs: { data: _vm.list, border: "", fit: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.gearsKind"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.packType))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2959015719
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.buyCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.queryResult))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1824536005
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("statManager.buyPersonCount"),
                  width: "120px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.distinctQueryResult))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  982632863
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }