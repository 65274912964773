var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading"
        }
      ],
      staticClass: "app-container"
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              ref: "listQuery",
              staticClass: "activity-form",
              attrs: { model: _vm.listQuery, "label-width": "200px" }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "filter-item",
                  attrs: {
                    "label-width": "120px",
                    label: _vm.$t("operation.reportDate")
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "date",
                      placeholder: _vm.$t("operation.selectDatetime"),
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      align: "right",
                      "unlink-panels": ""
                    },
                    model: {
                      value: _vm.listQuery.reportDate,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "reportDate", $$v)
                      },
                      expression: "listQuery.reportDate"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleFilter }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("statManager.search")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-download" },
                      on: { click: _vm.handleExport }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("statManager.export")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleFilter1 }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("statManager.rest")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          attrs: { data: _vm.list, border: "", fit: "" }
        },
        [
          _vm.listQuery.action === "rest"
            ? _c(
                "div",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.day30"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[7]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3099383193
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.program"),
                      width: "140px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[0]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3296229822
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.date"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[1]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2991457375
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.today"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[2]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      120760188
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.nextDay"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[3]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      4011980829
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.day3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[4]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2383632186
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.day7"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[5]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2078859739
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.day14"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_c("span", [_vm._v(_vm._s(scope.row[6]))])]
                          }
                        }
                      ],
                      null,
                      false,
                      3503129848
                    )
                  })
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.gachaRewardCount"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.gachaRewardCount))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.date"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.date))])]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.os"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.os))])]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Xinzeng"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.Xinzeng))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Rihuo"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.Rihuo))])]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Fufeirenshu"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.Fufeirenshu))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Xinzengfufeirenshu"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.Xinzengfufeirenshu))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Fufeijiner"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.Fufeijiner))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Xinzengfufeijiner"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.Xinzengfufeijiner))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Fufeilv"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.Fufeilv))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Xinzengfufeilv"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.Xinzengfufeilv))
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Arpu"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.Arpu))])]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Arppu"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.Arppu))])]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Vip7day"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.Vip7day))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Vip30day"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.Vip30day))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.Vip365day"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.Vip365day))])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      label: _vm.$t("default.operate"),
                      "min-width": "160"
                    }
                  })
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }