var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": _vm.$t("statManager.to"),
              "start-placeholder": _vm.$t("statManager.startTime"),
              "end-placeholder": _vm.$t("statManager.endTime"),
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.rangeTime,
              callback: function($$v) {
                _vm.rangeTime = $$v
              },
              expression: "rangeTime"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("statManager.pointContent"),
                clearable: ""
              },
              on: { change: _vm.handleCommand },
              model: {
                value: _vm.listQuery.type,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type"
              }
            },
            _vm._l(_vm.types, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.name, value: item.key }
              })
            }),
            1
          ),
          _vm.listQuery.type === 1
            ? _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { placeholder: _vm.$t("user.inputPlayerId") },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.uid,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "uid", $$v)
                  },
                  expression: "listQuery.uid"
                }
              })
            : _vm._e(),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("statManager.search")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          attrs: { data: _vm.list, border: "", fit: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: _vm.$t("default.operate"),
              "min-width": "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.listQuery.type === 5
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.editItem(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("default.edit")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          [0, 1].indexOf(_vm.listQuery.type) >= 0
            ? _c(
                "div",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("糖果"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.type))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1406222782
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("次数"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.time))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1740237331
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("赢取"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.gain))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      290734247
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("下注"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.cost))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2801155309
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("goodluck"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.goodluck))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3501118484
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("阿拉丁"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.alading))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1611055118
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.listQuery.type === 4
            ? _c(
                "div",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.date"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.date))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1531225394
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.coinProduct"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.queryPlayer2))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3679073997
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.coinconsume"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.queryActCost))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3043236017
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.succActivityNum"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.queryPlayer1))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2215546062
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.restOfPool"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.queryPool))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4234535088
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.rankCoin"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.rankCoin))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2851469019
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("statManager.sysGet"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.sysGetCoin))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      611007042
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("礼包出售"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.packSell))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2030373769
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("火车3"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.huoche3))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2621330441
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("火车4"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.huoche4))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2301071342
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("火车5"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.huoche5))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2734447567
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("小三元"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.xiaosanyuan))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3184856102
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("大三元"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.dasanyuan))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1834484252
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("小四喜"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.xiaosixi))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      680906418
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("阿拉丁"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.alading))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1611055118
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.listQuery.type === 5
            ? _c(
                "div",
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("每日抽成上限"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.maxSysGetMoney))
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      939357101
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("返奖预设"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.yushe))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1685182196
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("剩余场次"),
                      width: "120px",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.playCount))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1736929217
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("operation.addAndEdit"),
            visible: _vm.dialogEditorVisible,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEditorVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              staticClass: "activity-form",
              attrs: { model: _vm.formData, "label-width": "120px" }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    prop: "activityType",
                    label: _vm.$t("每日抽成上限")
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.formData.maxSysGetMoney,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "maxSysGetMoney", $$v)
                      },
                      expression: "formData.maxSysGetMoney"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    prop: "activityType",
                    label: _vm.$t("选择预设")
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "130px" },
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.formData.yushe,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "yushe", $$v)
                        },
                        expression: "formData.yushe"
                      }
                    },
                    _vm._l(_vm.yushetypes, function(item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.name, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    prop: "activityType",
                    label: _vm.$t("剩余场次")
                  }
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.formData.playCount,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "playCount", $$v)
                      },
                      expression: "formData.playCount"
                    }
                  })
                ],
                1
              ),
              _c("el-form-item", [
                _c(
                  "button",
                  {
                    staticClass: "el-button el-button--primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.saveOrUpdate()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("default.save")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }