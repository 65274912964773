var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "el-row",
        {
          staticClass: "panel-group",
          attrs: { loading: _vm.loading, gutter: 40 }
        },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function($event) {
                      return _vm.handleSetLineChartData("users")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-icon-wrapper icon-people" },
                    [
                      _c("svg-icon", {
                        staticClass: "card-panel-icon",
                        attrs: { name: "peoples" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("statManager.totalUser")) +
                            "\n          "
                        )
                      ]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.userCount,
                          duration: 2600
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 6 } },
            [
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function($event) {
                      return _vm.handleSetLineChartData("messages")
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-icon-wrapper icon-message" },
                    [
                      _c("svg-icon", {
                        staticClass: "card-panel-icon",
                        attrs: { name: "table" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("statManager.onlineRooms")) +
                            "\n          "
                        )
                      ]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.avrgTime,
                          duration: 3000
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px 0",
            "margin-bottom": "32px"
          }
        },
        [_c("line-chart", { attrs: { "chart-data": _vm.lineChartData } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }